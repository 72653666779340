import EventEmitter from 'events';

const eventPrifix = 'videosdk-live-react-sdk';

const events = {
  'participant-joined': `${eventPrifix}-participant-joined`,
  'participant-left': `${eventPrifix}-participant-left`,
  'speaker-changed': `${eventPrifix}-speaker-changed`,
  'presenter-changed': `${eventPrifix}-presenter-changed`,
  'main-participant-changed': `${eventPrifix}-main-participant-changed`,
  'entry-requested': `${eventPrifix}-entry-requested`,
  'entry-responded': `${eventPrifix}-entry-responded`,
  'recording-started': `${eventPrifix}-recording-started`,
  'recording-stopped': `${eventPrifix}-recording-stopped`,
  'chat-message': `${eventPrifix}-chat-message`,
  'meeting-joined': `${eventPrifix}-meeting-joined`,
  'meeting-left': `${eventPrifix}-meeting-left`,
  'live-stream-started': `${eventPrifix}-live-stream-started`,
  'live-stream-stopped': `${eventPrifix}-live-stream-stopped`,
  'video-state-changed': `${eventPrifix}-video-state-changed`,
  'video-seeked': `${eventPrifix}-video-seeked`,
  'webcam-requested': `${eventPrifix}-webcam-requested`,
  'mic-requested': `${eventPrifix}-mic-requested`,
  'pin-state-changed': `${eventPrifix}-pin-state-changed`,
  'connection-open': `${eventPrifix}-connection-open`,
  'connection-close': `${eventPrifix}-connection-close`,
  'switch-meeting': `${eventPrifix}-switch-meeting`,
  error: `${eventPrifix}-error`,
  'hls-started': `${eventPrifix}-hls-started`,
  'hls-stopped': `${eventPrifix}-hls-stopped`
};

const eventEmitter = new EventEmitter();
eventEmitter.setMaxListeners(9999);

export { eventEmitter, events };
