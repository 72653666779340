import { useEffect, useRef, useState } from 'react';
import { useMeeting } from '../meeting/meetingProviderContextDef';

const usePubSub = (
  topic,
  { onMessageReceived = () => {}, onOldMessagesReceived = () => {} } = {}
) => {
  const { meeting, isMeetingJoined } = useMeeting();
  const [messages, setMessages] = useState([]);
  const isSubscribed = useRef(false);
  const publish = (message, options) => {
    if (meeting) {
      meeting.pubSub.publish(topic, message, options);
    }
  };

  const _handlePubSub = data => {
    setMessages(s => [...s, data]);
    onMessageReceived(data);
  };

  const subscribeToPubsub = async meeting => {
    const data = await meeting?.pubSub?.subscribe(topic, _handlePubSub);
    if (data) {
      onOldMessagesReceived(data.messages);
      setMessages(data.messages);
    }
  };

  useEffect(() => {
    if (meeting && !isSubscribed.current && isMeetingJoined) {
      isSubscribed.current = true;
      subscribeToPubsub(meeting);

      return () => {
        meeting?.pubSub?.unsubscribe(topic, _handlePubSub);
      };
    }
  }, [meeting, isMeetingJoined]);

  return { publish, messages };
};

export default usePubSub;
