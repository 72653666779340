import { MeetingProviderContex } from './meetingProviderContextDef';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { eventEmitter, events } from '../utils';
import { VideoSDK } from '@videosdk.live/js-sdk';
import { version as reactSDKVersion } from '../../package.json';

const MeetingProvider = ({
  children,
  config,
  token,
  joinWithoutUserInteraction,
  reinitialiseMeetingOnConfigChange: _reinitialiseMeetingOnConfigChange,
  deviceInfo
}) => {
  const [meeting, setMeeting] = useState(null);
  const [localParticipant, setLocalParticipant] = useState(null);
  const [mainParticipant, setMainParticipant] = useState(null);
  const [activeSpeakerId, setActiveSpeakerId] = useState(null);
  const [presenterId, setPresenterId] = useState(null);
  const [localMicOn, setLocalMicOn] = useState(false);
  const [localWebcamOn, setLocalWebcamOn] = useState(false);
  const [localScreenShareOn, setLocalScreenShareOn] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [messages, setMessages] = useState([]);
  const [participants, setParticipants] = useState(new Map());
  const [pinnedParticipants, setPinnedParticipants] = useState(new Map());
  const [isLiveStreaming, setIsLiveStreaming] = useState(false);
  const [connections, setConnections] = useState(new Map());
  const [isMeetingJoined, setIsMeetingJoined] = useState(false);
  const [isHls, setIsHls] = useState(false);

  const meetingRef = useRef();
  const localMicOnRef = useRef();
  const localWebcamOnRef = useRef();
  const localScreenShareOnRef = useRef();
  const isRecordingRef = useRef();
  const configRef = useRef(config);
  const tokenRef = useRef(token);
  const joinedOnFirstRender = useRef(false);
  const isHlsRef = useRef();

  const reinitialiseMeetingOnConfigChange = useMemo(
    () => _reinitialiseMeetingOnConfigChange,
    []
  );

  useEffect(() => {
    meetingRef.current = meeting;
  }, [meeting]);
  useEffect(() => {
    localMicOnRef.current = localMicOn;
  }, [localMicOn]);
  useEffect(() => {
    localWebcamOnRef.current = localWebcamOn;
  }, [localWebcamOn]);
  useEffect(() => {
    localScreenShareOnRef.current = localScreenShareOn;
  }, [localScreenShareOn]);
  useEffect(() => {
    isRecordingRef.current = isRecording;
  }, [isRecording]);
  useEffect(() => {
    configRef.current = config;
  }, [config]);
  useEffect(() => {
    tokenRef.current = token;
  }, [token]);
  useEffect(() => {
    isHlsRef.current = isHls;
  }, [isHls]);

  const resetStates = () => {
    setMeeting(null);
    setLocalParticipant(null);
    setMainParticipant(null);
    setActiveSpeakerId(null);
    setPresenterId(null);
    setLocalMicOn(false);
    setLocalWebcamOn(false);
    setLocalScreenShareOn(false);
    setIsRecording(false);
    setMessages([]);
    setIsLiveStreaming(false);
    setParticipants(new Map());
    setPinnedParticipants(new Map());
    setConnections(new Map());
    setIsMeetingJoined(false);
    setIsHls(false);
  };

  const _handle_participant_joined = participant => {
    setParticipants(participants => {
      participants.set(participant.id, participant);
      const participantsToSet = new Map(participants);

      return participantsToSet;
    });
    eventEmitter.emit(events['participant-joined'], participant);
  };
  const _handle_participant_left = participant => {
    setParticipants(participants => {
      participants.delete(participant.id);

      const newParticipants = new Map(participants);
      return newParticipants;
    });
    eventEmitter.emit(events['participant-left'], participant);
  };
  const _handle_presenter_changed = presenterId => {
    setPresenterId(presenterId);
    setLocalScreenShareOn(
      presenterId === meetingRef?.current?.localParticipant?.id
    );
    eventEmitter.emit(events['presenter-changed'], presenterId);
  };
  const _handle_main_participant_changed = participant => {
    setMainParticipant(participant);
    eventEmitter.emit(events['main-participant-changed'], participant);
  };
  const _handle_speaker_changed = activeSpeakerId => {
    setActiveSpeakerId(activeSpeakerId);
    eventEmitter.emit(events['speaker-changed'], activeSpeakerId);
  };
  const _handle_chat_message = data => {
    setMessages(s => [...s, data]);

    eventEmitter.emit(events['chat-message'], data);
  };
  const _handle_entry_requested = data => {
    eventEmitter.emit(events['entry-requested'], data);
  };
  const _handle_entry_responded = (participantId, decision) => {
    eventEmitter.emit(events['entry-responded'], participantId, decision);
  };
  const _handle_recording_started = () => {
    setIsRecording(true);
    eventEmitter.emit(events['recording-started']);
  };
  const _handle_recording_stopped = () => {
    setIsRecording(false);
    eventEmitter.emit(events['recording-stopped']);
  };

  const _handle_localParticipant_stream_enabled = stream => {
    if (stream.track.readyState === 'live') {
      if (stream.kind === 'video') {
        setLocalWebcamOn(true);
      } else if (stream.kind === 'audio') {
        setLocalMicOn(true);
      }
    }
  };
  const _handle_localParticipant_stream_disabled = stream => {
    if (stream.track.readyState === 'ended') {
      if (stream.kind === 'video') {
        setLocalWebcamOn(false);
      } else if (stream.kind === 'audio') {
        setLocalMicOn(false);
      }
    }
  };
  const _handle_live_stream_started = data => {
    setIsLiveStreaming(true);
    eventEmitter.emit(events['live-stream-started'], data);
  };
  const _handle_live_stream_stopped = () => {
    setIsLiveStreaming(false);
    eventEmitter.emit(events['live-stream-stopped']);
  };
  const _handle_hls_started = data => {
    setIsHls(true);
    eventEmitter.emit(events['hls-started'], data);
  };
  const _handle_hls_stopped = () => {
    setIsHls(false);
    eventEmitter.emit(events['hls-stopped']);
  };
  const _handle_video_state_changed = data => {
    eventEmitter.emit(events['video-state-changed'], data);
  };
  const _handle_video_seeked = data => {
    eventEmitter.emit(events['video-seeked'], data);
  };

  const _handle_webcam_requested = data => {
    eventEmitter.emit(events['webcam-requested'], data);
  };

  const _handle_mic_requested = data => {
    eventEmitter.emit(events['mic-requested'], data);
  };

  const _handle_meeting_joined = data => {
    joinedOnFirstRender.current = true;
    setMessages(s => [...s, ...data.messages]);
    eventEmitter.emit(events['meeting-joined'], data);
    setIsMeetingJoined(true);
  };

  const _handle_meeting_left = () => {
    eventEmitter.emit(events['meeting-left']);

    const meeting = meetingRef.current;

    if (meeting) {
      if (typeof meeting?.off === 'function') {
        meeting.off('participant-joined', _handle_participant_joined);
        meeting.off('participant-left', _handle_participant_left);
        meeting.off('presenter-changed', _handle_presenter_changed);
        meeting.off(
          'main-participant-changed',
          _handle_main_participant_changed
        );
        meeting.off('speaker-changed', _handle_speaker_changed);
        meeting.off('entry-requested', _handle_entry_requested);
        meeting.off('entry-responded', _handle_entry_responded);
        meeting.off('chat-message', _handle_chat_message);
        meeting.off('recording-started', _handle_recording_started);
        meeting.off('recording-stopped', _handle_recording_stopped);
        meeting.off('meeting-joined', _handle_meeting_joined);
        meeting.off('meeting-left', _handle_meeting_left);
        meeting.off('livestream-started', _handle_live_stream_started);
        meeting.off('livestream-stopped', _handle_live_stream_stopped);
        meeting.off('video-state-changed', _handle_video_state_changed);
        meeting.off('video-seeked', _handle_video_seeked);
        meeting.off('webcam-requested', _handle_webcam_requested);
        meeting.off('mic-requested', _handle_mic_requested);
        meeting.off('pin-state-changed', _handle_pin_state_changed);
        meeting.off('connection-open', _handle_connection_open);
        meeting.off('connection-close', _handle_connection_close);
        meeting.off('switch-meeting', _handle_switch_meeting);
        meeting.off('error', _handle_error);
        meeting.off('hls-started', _handle_hls_started);
        meeting.off('hls-stopped', _handle_hls_stopped);
      }

      if (typeof meeting?.localParticipant?.off === 'function') {
        meeting.localParticipant.off(
          'stream-enabled',
          _handle_localParticipant_stream_enabled
        );

        meeting.localParticipant.off(
          'stream-disabled',
          _handle_localParticipant_stream_disabled
        );
      }

      resetStates();
    }
  };

  const _handle_pin_state_changed = ({ participantId, state, pinnedBy }) => {
    setPinnedParticipants(pinnedParticipants => {
      if (!state.cam && !state.share) {
        pinnedParticipants.delete(participantId);
      } else {
        pinnedParticipants.set(participantId, state);
      }

      const pinnedParticipantsToSet = new Map(pinnedParticipants);

      return pinnedParticipantsToSet;
    });

    eventEmitter.emit(events['pin-state-changed'], {
      participantId,
      state,
      pinnedBy
    });
  };

  const _handle_connection_open = connection => {
    setConnections(s => {
      s.set(connection.id, connection);
      const connectionsToSet = new Map(s);
      return connectionsToSet;
    });
    eventEmitter.emit(events['connection-open'], connection);
  };

  const _handle_connection_close = connectionId => {
    setConnections(s => {
      s.delete(connectionId);
      const newConnections = new Map(s);
      return newConnections;
    });
    eventEmitter.emit(events['connection-close'], connectionId);
  };

  const _handle_switch_meeting = d => {
    eventEmitter.emit(events['switch-meeting'], d);
  };
  const _handle_error = data => {
    eventEmitter.emit(events['error'], data);
  };

  const join = () => {
    const meeting = VideoSDK && VideoSDK.initMeeting(configRef.current);
    setMeeting(meeting);
    const { localParticipant, participants } = meeting;
    participants.set(localParticipant.id, localParticipant);
    setParticipants(participants);
    setLocalParticipant(localParticipant);
    meeting.on('participant-joined', _handle_participant_joined);
    meeting.on('participant-left', _handle_participant_left);
    meeting.on('presenter-changed', _handle_presenter_changed);
    meeting.on('main-participant-changed', _handle_main_participant_changed);
    meeting.on('speaker-changed', _handle_speaker_changed);
    meeting.on('entry-requested', _handle_entry_requested);
    meeting.on('entry-responded', _handle_entry_responded);
    meeting.on('chat-message', _handle_chat_message);
    meeting.on('recording-started', _handle_recording_started);
    meeting.on('recording-stopped', _handle_recording_stopped);
    meeting.on('meeting-joined', _handle_meeting_joined);
    meeting.on('meeting-left', _handle_meeting_left);
    meeting.on('livestream-started', _handle_live_stream_started);
    meeting.on('livestream-stopped', _handle_live_stream_stopped);
    meeting.on('video-state-changed', _handle_video_state_changed);
    meeting.on('video-seeked', _handle_video_seeked);
    meeting.on('webcam-requested', _handle_webcam_requested);
    meeting.on('mic-requested', _handle_mic_requested);
    meeting.on('pin-state-changed', _handle_pin_state_changed);

    meeting.on('connection-open', _handle_connection_open);
    meeting.on('connection-close', _handle_connection_close);
    meeting.on('switch-meeting', _handle_switch_meeting);
    meeting.on('error', _handle_error);
    meeting.on('hls-started', _handle_hls_started);
    meeting.on('hls-stopped', _handle_hls_stopped);

    meeting.localParticipant.on(
      'stream-enabled',
      _handle_localParticipant_stream_enabled
    );
    meeting.localParticipant.on(
      'stream-disabled',
      _handle_localParticipant_stream_disabled
    );
    meeting.join();
  };

  const leave = () => {
    const meeting = meetingRef.current;

    if (meeting) {
      meeting.leave();
    }
  };

  const end = () => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.end();
    }
  };
  const startRecording = (webhookUrl, awsDirPath, config) => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.startRecording(webhookUrl, awsDirPath, config);
    }
  };
  const stopRecording = () => {
    const meeting = meetingRef.current;
    if (meeting) {
      const isRecording = isRecordingRef.current;
      if (isRecording) {
        meeting.stopRecording();
      }
    }
  };
  const unmuteMic = (customAudioTrack = undefined) => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.unmuteMic(customAudioTrack);
    }
  };
  const muteMic = () => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.muteMic();
    }
  };
  const toggleMic = (customAudioTrack = undefined) => {
    const localMicOn = localMicOnRef.current;

    if (localMicOn) {
      muteMic();
    } else {
      unmuteMic(customAudioTrack);
    }
  };
  const disableWebcam = () => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.disableWebcam();
    }
  };
  const enableWebcam = (customVideoTrack = undefined) => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.enableWebcam(customVideoTrack);
    }
  };
  const toggleWebcam = (customVideoTrack = undefined) => {
    const localWebcamOn = localWebcamOnRef.current;

    if (localWebcamOn) {
      disableWebcam();
    } else {
      enableWebcam(customVideoTrack);
    }
  };
  const disableScreenShare = () => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.disableScreenShare();
    }
  };
  const enableScreenShare = (customScreenShareTrack = undefined) => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.enableScreenShare(customScreenShareTrack);
    }
  };
  const toggleScreenShare = (customScreenShareTrack = undefined) => {
    const localScreenShareOn = localScreenShareOnRef.current;

    if (localScreenShareOn) {
      disableScreenShare();
    } else {
      enableScreenShare(customScreenShareTrack);
    }
  };
  const sendChatMessage = text => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.sendChatMessage(text);
    }
  };
  const respondEntry = (participantId, decision) => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.respondEntry(participantId, decision);
    }
  };
  const getMics = async () => {
    const meeting = meetingRef.current;
    if (meeting) {
      return await meeting.getMics();
    }
  };
  const getWebcams = async () => {
    const meeting = meetingRef.current;
    if (meeting) {
      return await meeting.getWebcams();
    }
  };
  const changeWebcam = obj => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.changeWebcam(obj);
    }
  };
  const changeMic = obj => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.changeMic(obj);
    }
  };
  const startVideo = ({ link }) => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.startVideo({ link });
    }
  };

  const stopVideo = () => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.stopVideo();
    }
  };

  const resumeVideo = () => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.resumeVideo();
    }
  };

  const pauseVideo = ({ currentTime }) => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.pauseVideo({ currentTime });
    }
  };

  const seekVideo = ({ currentTime }) => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.seekVideo({ currentTime });
    }
  };

  const startLivestream = (streamInfo, config) => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.startLivestream(streamInfo, config);
    }
  };

  const stopLivestream = () => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.stopLivestream();
    }
  };

  const connectTo = async ({ meetingId, payload }) => {
    const meeting = meetingRef.current;
    if (meeting) {
      await meeting.connectTo({ meetingId, payload });
    }
  };

  const startHls = config => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.startHls(config);
    }
  };

  const stopHls = () => {
    const meeting = meetingRef.current;
    if (meeting) {
      meeting.stopHls();
    }
  };

  const initSDK = () => {
    VideoSDK.config(tokenRef.current);

    if (deviceInfo) {
      VideoSDK.analytics(deviceInfo);
    } else {
      VideoSDK.analytics({
        sdkType: 'react-web',
        sdkVersion: reactSDKVersion
      });
    }

    if (joinWithoutUserInteraction) {
      join();
    }
  };

  useEffect(() => {
    initSDK();
  }, []);

  useEffect(() => {
    if (reinitialiseMeetingOnConfigChange && joinedOnFirstRender.current) {
      leave();
      initSDK();
      !joinWithoutUserInteraction && join();
    }
  }, [config.meetingId, token, reinitialiseMeetingOnConfigChange]);

  return (
    <MeetingProviderContex.Provider
      value={{
        meetingId: meeting?.id,
        meeting,
        localParticipant,
        mainParticipant,
        activeSpeakerId,
        participants,
        presenterId,
        localMicOn,
        localWebcamOn,
        localScreenShareOn,
        messages,
        isRecording,
        isLiveStreaming,
        pinnedParticipants,
        connections,
        isHls,
        //
        join,
        leave,
        end,
        //
        startRecording,
        stopRecording,
        //
        sendChatMessage,
        respondEntry,
        //
        muteMic,
        unmuteMic,
        toggleMic,
        //
        disableWebcam,
        enableWebcam,
        toggleWebcam,
        //
        disableScreenShare,
        enableScreenShare,
        toggleScreenShare,
        //
        getMics,
        getWebcams,
        changeWebcam,
        changeMic,

        startVideo,
        stopVideo,
        resumeVideo,
        pauseVideo,
        seekVideo,
        startLivestream,
        stopLivestream,
        connectTo,
        isMeetingJoined,
        startHls,
        stopHls
      }}
    >
      {children}
    </MeetingProviderContex.Provider>
  );
};

export default MeetingProvider;
