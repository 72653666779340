import { useEffect, useState, useMemo } from 'react';
import { useMeeting } from '../meeting/meetingProviderContextDef';

const useParticipant = (
  participantId,
  { onStreamEnabled = () => {}, onStreamDisabled = () => {} } = {}
) => {
  const [webcamStream, setwebcamStream] = useState(null);
  const [micStream, setMicStream] = useState(null);
  const [screenShareStream, setScreenShareStream] = useState(null);

  const meeting = useMeeting();

  const {
    participants,
    localParticipant,
    activeSpeakerId,
    mainParticipant,
    pinState
  } = useMemo(() => {
    const participants = meeting?.participants;
    const localParticipant = meeting?.localParticipant;
    const activeSpeakerId = meeting?.activeSpeakerId;
    const mainParticipant = meeting?.mainParticipant;
    const pinState = meeting?.pinnedParticipants?.get(participantId) || {
      cam: false,
      share: false
    };

    return {
      participants,
      localParticipant,
      activeSpeakerId,
      mainParticipant,
      pinState
    };
  }, [meeting]);

  const participant = participants?.get(participantId);

  const setTrack = stream => {
    if (stream.track.readyState === 'live') {
      switch (stream.kind) {
        case 'video':
          setwebcamStream(stream);
          break;
        case 'audio':
          setMicStream(stream);
          break;
        case 'share':
          setScreenShareStream(stream);
          break;
        default:
          break;
      }
    }
  };

  const unSetTrack = stream => {
    if (stream.track.readyState !== 'live') {
      switch (stream.kind) {
        case 'video':
          setwebcamStream(null);
          break;
        case 'audio':
          setMicStream(null);
          break;
        case 'share':
          setScreenShareStream(null);
          break;
        default:
          break;
      }
    }
  };

  const _handleStreamEnabled = stream => {
    setTrack(stream);
    onStreamEnabled(stream);
  };

  const _handleStreamDisabled = stream => {
    unSetTrack(stream);
    onStreamDisabled(stream);
  };

  const setQuality = quality => {
    participant?.setQuality(quality);
  };
  const enableMic = () => {
    participant?.enableMic();
  };
  const disableMic = () => {
    participant?.disableMic();
  };
  const enableWebcam = () => {
    participant?.enableWebcam();
  };
  const disableWebcam = () => {
    participant?.disableWebcam();
  };
  const pin = data => {
    participant?.pin(data);
  };
  const unpin = data => {
    participant?.unpin(data);
  };

  const switchTo = async data => {
    await participant?.switchTo(data);
  };

  useEffect(() => {
    const streams = participant?.streams;

    if (streams) {
      streams.forEach(stream => {
        setTrack(stream);
      });
    }

    participant?.on('stream-enabled', _handleStreamEnabled);

    participant?.on('stream-disabled', _handleStreamDisabled);

    return () => {
      participant?.off('stream-enabled', _handleStreamEnabled);

      participant?.off('stream-disabled', _handleStreamDisabled);
    };
  }, [participant]);

  return {
    displayName: participant?.displayName,
    participant,
    webcamStream,
    micStream,
    screenShareStream,
    webcamOn: !!webcamStream,
    micOn: !!micStream,
    screenShareOn: !!screenShareStream,
    isLocal: localParticipant?.id === participantId,
    isActiveSpeaker: activeSpeakerId === participantId,
    isMainParticipant: mainParticipant?.id === participantId,
    pinState,

    //
    setQuality,
    enableMic,
    disableMic,
    enableWebcam,
    disableWebcam,
    pin,
    unpin,
    switchTo
  };
};

export default useParticipant;
